import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"

Dropzone.autoDiscover = false

export default class extends Controller {
  connect() {
    this.uploaded = false

    var myDropzone = new Dropzone(this.element, {
      addRemoveLinks: true,
      maxFilesize: (3 * 1024)
    });

    myDropzone.on("success", (file, response) => {
      const inputId = `file-item-${response.id}`
      let input = $(`<input id='${inputId}' type='hidden' name='upload_form[file_ids][]'></input>`)
      input.val(response.id)
      file["inputId"] = inputId
  
      $("#new_upload_form").append(input)

      this.uploaded = true
      $("#new_upload_form input[type='submit']").val("Send")
      this.toggleSubmitButton()
    });

    myDropzone.on("addedfile", ()=> {
      $("#new_upload_form input[type='submit']").val("Uploading...")
    })
  
    myDropzone.on("removedfile", function(file){
      $(`#${file.inputId}`).remove()

      if ($(".dz-preview").length === 0) {
        this.uploaded = false
        $("#new_upload_form input[type='submit']").attr("disabled", true)
      }
    })

    $("#uploads-page form").on("keyup", "#upload_form_name, #upload_form_email", ()=>{
      this.toggleSubmitButton()
    })
  }

  toggleSubmitButton() {
    if ($("#uploads-page form").valid() && this.uploaded) {
      $("#new_upload_form input[type='submit']").attr("disabled", false)
    } else {
      $("#new_upload_form input[type='submit']").attr("disabled", true)
    }
  }
}
