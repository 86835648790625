function showReel() {
  $('.btn-show-reel').on('click', function() {
    $('.full-screen-wrapper').fadeIn(1200);
  });

  $('.btn-close-show-reel').on('click', function() {
    $('.full-screen-wrapper').fadeOut(1200);
  });

};

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
  }
  return false;
};

function renderPackageDetail(isWebsite, isBranding, isSocial, isArticle, isFull) {
  if (isWebsite) {
    $('.packages-container > [data-id="package-website"]').show();
    $('#website').addClass('bold');
  } else {
    $('.packages-container > [data-id="package-website"]').hide();
    $('#website').removeClass('bold');
  }
  if (isBranding) {
    $('.packages-container > [data-id="package-branding"]').show();
    $('#branding').addClass('bold');
  } else {
    $('.packages-container > [data-id="package-branding"]').hide();
    $('#branding').removeClass('bold');
  }
  if (isSocial) {
    $('.packages-container > [data-id="package-social"]').show();
    $('#social').addClass('bold');
  } else {
    $('.packages-container > [data-id="package-social"]').hide();
    $('#social').removeClass('bold');
  }
  if (isFull) {
    $('.packages-container > [data-id="package-full"]').show();
    $('#fullService').addClass('bold');
  } else {
    $('.packages-container > [data-id="package-full"]').hide();
    $('#fullService').removeClass('bold');
  }
  if (isArticle) {
    $('.packages-container > [data-id="package-article"]').show();
    $('#article').addClass('bold');
  } else {
    $('.packages-container > [data-id="package-article"]').hide();
    $('#article').removeClass('bold');
  }
  if (isArticle || isFull || isSocial || isBranding || isWebsite) {
    scrollTo('#packages', 500);
  }
}

function scrollTo(id = "", scrollSpeed = 0) {
  if ($("#home-index").length > 0) {
    const $container = $("main.snap-container");

    $("body, html, main.snap-container").animate(
      {
        scrollTop: $container.scrollTop() + $(id).offset().top,
      },
      scrollSpeed
    );
  } else {
    window.location = "/" + id;
  }
}

function handleInitialRender() {
  const isWebsite = getUrlParameter('website');
  const isBranding = getUrlParameter('branding');
  const isSocial = getUrlParameter('social');
  const isFull = getUrlParameter('full');
  const isArticle = getUrlParameter('article');
  renderPackageDetail(isWebsite, isBranding, isSocial, isArticle, isFull);
  if (!isWebsite && !isBranding && !isSocial && !isFull && !isArticle)  {
    $('.packages-container > [data-id="package-social"]').show();
  }
}

function packageInfo() {
  handleInitialRender();
  $('.service-item .img-info').on('click', function() {
    $(this).parent().parent().siblings('.service-details').toggleClass('active');
  });
  $('.packge-item').on('click', function() {
    const tab = this.id;
    switch (tab) {
      case 'website':
        renderPackageDetail(true, false, false, false, false);
        break;
      case 'branding':
        renderPackageDetail(false, true, false, false, false);
        break;
      case 'social':
        renderPackageDetail(false, false, true, false, false);
        break;
      case 'article':
        renderPackageDetail(false, false, false, true, false);
        break;
      case 'fullService':
        renderPackageDetail(false, false, false, false, true);
        break;
    }
  });
}

export { showReel, packageInfo };