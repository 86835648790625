function hiddenTextHandler() {
  $('.hidden-content-wrapper').on('click', function() {
    var hasClassHidden = $(this).find('.hidden-content').hasClass('hidden');

    if (hasClassHidden) {
      if ($(".hidden-content").width() <= 300) {
        $('.read-more').removeClass('d-none');
        $('.spin-arrow').css("transform", "unset");
        $('.hidden-content').slideUp(50);
        $('.hidden-content').addClass('hidden');
      }

      $(this).find('.spin-arrow').css("transform", "rotate(180deg)");
      $(this).find('.read-more').addClass('d-none');
      $(this).find('.hidden-content').removeClass('hidden');
      $(this).find('.hidden-content').slideDown(500);

      if ($(".hidden-content").width() <= 300) {
        const $container = $('main.snap-container');
        $container.animate({
          scrollTop: $container.scrollTop() + $("#snap-child-mobile").offset().top
        }, 300);
      }
    } else {
      $(this).find('.read-more').removeClass('d-none');
      $(this).find('.spin-arrow').css("transform", "unset");
      $(this).find('.hidden-content').slideUp(500);
      $(this).find('.hidden-content').addClass('hidden');
    }
  });
}

export { hiddenTextHandler };
