// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("channels")
require("popper.js");
require("bootstrap");
require('jquery-validation');
import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import { showReel, packageInfo } from '../components/homepage-handler';
import { hiddenTextHandler } from '../components/hidden-text';
import { brandFactFinder } from '../components/brand_fact_finder';
import { navigateHandler, scrollHandler, handleMenu } from '../components/navigate';
window.$ = $;

import Sortable from 'sortablejs';
import flatpickr from "flatpickr";
import "controllers";

require("@nathanvda/cocoon")

import Cookies from 'js-cookie';
window.cookies = Cookies;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js", {
        scope: "/",
      });
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

$(document).ready(function() {
  showReel();
  packageInfo();
  hiddenTextHandler();
  brandFactFinder();
  navigateHandler();
  scrollHandler();
  handleMenu();
  registerServiceWorker();

  $(".mail-1").html("<a href='mailto:matt@ashby.co.uk' class='text-white'>matt@ashby.co.uk</a>")
  $(".mail-2").html("<a href='mailto:louise@ashby.co.uk' class='text-white'>louise@ashby.co.uk</a>")

  // Detects if device is on iOS 
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  $(".ios-pwa-prompt").on("click", "a.close", (event)=>{
    event.preventDefault()
    console.log("click close")
    Cookies.set("close-pwa-prompt", "true")
    $(".ios-pwa-prompt").addClass("d-none")
    $(".ios-pwa-prompt").removeClass("d-flex")
  })

  // Checks if should display install popup notification:
  if (isIos() && !isInStandaloneMode()) {
    const promptCloseState = Cookies.get("close-pwa-prompt")

    if (promptCloseState === undefined) {
      $(".ios-pwa-prompt").removeClass("d-none")
      $(".ios-pwa-prompt").addClass("d-flex")
    }
  }

  if (Cookies.get('allow_cookies') == undefined) {
    $("#cookie-message").animate({bottom: '0'}, 'slow');
  } else {
    $("#cookie-message").remove();
    $("#cookie-info-modal").remove();
  }

  $("#cookie-message .close").click(function(){
    location.reload();
    $("#cookie-message").remove();
    Cookies.set('allow_cookies', 'yes', { expires: 365 });
  });

  if ($("#images").length > 0) {
    var el = document.getElementById('images');
    var sortable = Sortable.create(el, {
      draggable: '.nested-fields',
      onUpdate: updateImagesOrder
    });
  }


  $('#images')
    .on('cocoon:after-insert', updateImagesOrder)
    .on("cocoon:after-remove", updateImagesOrder);

  $("form").on("change", "input.file-input", function(){
    console.log(this.files)
    var image = this.files[0]

    if (image) {
      $(this).closest(".img-cover").find("img")[0].src = URL.createObjectURL(image);
    }
  })

  flatpickr("#post_published_at", { enableTime: true });
});

function updateImagesOrder(){
  var images = $('#images .nested-fields');
  $.each(images, function(i, image){
    console.log(images.length - i);

    $(image).find('input[id$=_order]').val(images.length - i)
  })
}

require("trix")
require("@rails/actiontext")
