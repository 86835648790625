import Cookies from 'js-cookie';
import $ from 'jquery';

$(document).ready(function() {
  window.brandSlider = $('#brand-fact-finder-slider');
  
  // Fires before when the slide moved.
  window.brandSlider.on('slide.bs.carousel', function () {
    console.log('..slide.bs.carousel..')
    const $elem = window.brandSlider.find('.carousel-item.active');
    const $nextSlide = $elem.next();
    addBackground($elem);
    addOverflow($nextSlide);
    // if($nextSlide.length > 0) scrollToTop($nextSlide);
  })
  
  window.brandSlider.on('slid.bs.carousel', function () {
    const $elem = window.brandSlider.find('.carousel-item.active');
    scrollToTop($elem);
  });
})

// It's to fix white line between slide
function addBackground($elem) {
  const $container = window.brandSlider.find('.carousel-inner')
  $container.css('background-color', $elem.css('background-color'));
}

function addOverflow($elem, bool = true) {
  $elem.toggleClass('overflow-y', bool);
}

function brandFactFinder() {
  console.log('brandFactFinder', window.brandSlider);
  insertValueFromCookie();
  

  window.brandSlider.on('click', '.js-next', goNext);
  window.brandSlider.on('click', '.js-take-a-look', goTakeALook);
  window.brandSlider.on('click', '.js-to-form', goForm);
  window.brandSlider.on('click', '.js-to-get-start', goGetStart);
  window.brandSlider.on('click', '.js-lock', lockPage);
  window.brandSlider.on('click', '.js-prev', goPrevious);
  window.brandSlider.on('click', '.js-submit', submitNow);
  window.brandSlider.on('click', '.js-finish', finish);
  // window.brandSlider.on('click', '.js-time-start', setTimeStart);
  validateUserEmailForm();
  goOutFromMenuItem();

  if (checkFlexGap()) {
    document.documentElement.classList.add("flexbox-gap");
  } else {
    document.documentElement.classList.add("no-flexbox-gap");
  }
}

function goOutFromMenuItem() {
  $('.menu-item').on('click', function() {
    $('body').removeClass('lock-tool');
    window.brandSlider.carousel(0);
  });
}


function submitNow(e) {
  removeCookies(); // remove all previous cookie
  setTimeStart();
  if(validateForm()) {
    setCookie(e);
    changeUserEmail();
    submitForm();
    window.brandSlider.carousel('next');
  }
}

function setTimeStart() {
  const now = new Date().toUTCString();
  Cookies.set('brand_finder_contact[time_start]', now);
  console.log('set time start: ', now);
}

function removeTimeEnd() {
  Cookies.remove('brand_finder_contact[time_end]');
}
function setTimeEnd() {
  const now = new Date().toUTCString();
  Cookies.set('brand_finder_contact[time_end]', now);
  console.log('set time end: ', now);
}
function validateForm() {
  return $('form.brand_finder_contact').valid();
}

function validateUserEmailForm() {
  $('form.brand_finder_contact').validate({
    rules: {
      "brand_finder_contact[name]": "required",
      "brand_finder_contact[email]": {
        required: true,
        email: true
      }
    },
    messages: {
      "brand_finder_contact[name]": "Please provide your name.",
      "brand_finder_contact[email]": "Please provide your email."
    }
  });
}

function changeUserEmail() {
  const email = Cookies.get('brand_finder_contact[email]');
  $('.js-user-email').html(email)
}

function insertValueFromCookie() {
  const keys = getCookieKeys();
  keys.forEach(function(key) {
    let $elem = $(`[name="${key}"]`)
    if($elem.length != 0) {
      $elem.val(Cookies.get(key));
    }
  });
}

function finish(e) {
  e.preventDefault();
  setTimeEnd();
  goNext(e);
  lockPage(e, false);
  removeCookies();
  removeInputValue();
  window.brandSlider.carousel(0);
}

function submitForm(){
  const email = Cookies.get('brand_finder_contact[email]');
  if (!email) {
    console.error('..no email..')
    return; // No email
  }
  const data = getDataAttributes();

  console.log('submit', data);

  $.ajax({
    url: "/brand_fact_finder_tool",
    type: "POST",
    beforeSend: $.rails.CSRFProtection,
    data: {
      contact: data
    },
    success: function(data) {
      console.log('succes', data);
    }
  });
}

function getDataAttributes() {
  const keys = getCookieKeys();
  const data = { answers: {}, socials: {}};
  const answer_keys = keys.filter(function(k) { return /answers/.test(k) });
  const social_keys = keys.filter(function(k) { return /socials/.test(k) });
  const answers = answer_keys.map(function(k) { return k.replace('brand_finder[answers][', '').replace(']','') });
  const socials = social_keys.map(function(k) { return k.replace('brand_finder[socials][', '').replace(']','') });

  keys.forEach(function(key) {
    const attr = getAttrName(key);
    if(answers.includes(attr)) {
      data['answers'][attr] = Cookies.get(key)
    } else if(socials.includes(attr)) {
      data['socials'][attr] = Cookies.get(key)
    } else{
      data[attr] = Cookies.get(key)
    }
  });

  return data;
}

function removeCookies() {
  const keys = getCookieKeys();
  console.log('cookies', keys);
  // Cookies.remove('name');
  keys.forEach((key)=> Cookies.remove(key));
}

function removeInputValue() {
  const inputs =  window.brandSlider.find('textarea,input');
  inputs.each((_,input) => $(input).val(''));
}

function getAttrName(key){
  return key.replace(/^.+\[/, '').replace(']','');
}

function getCookieKeys() {
  return Object.keys(Cookies.get()).filter(function(k) { return /^brand_finder/.test(k) });
}

function goGetStart(e) {window.brandSlider.carousel(1);}
function goTakeALook(e) {window.brandSlider.carousel('next');}
function goForm(e) {
  window.brandSlider.carousel(3);
}

function goNext(e) {
  e.preventDefault();
  setCookie(e);
  const valid = validateInputValue(e)
  const data = getDataAttributes();
  const $inputs = getInputs(e);

  if(valid) {
    window.brandSlider.carousel('next');
    if($inputs.length > 0) updateData(data);
  }
}

function updateData(data) {
  $.ajax({
    url: "/brand_fact_finder_tool",
    type: "PUT",
    beforeSend: $.rails.CSRFProtection,
    data: {
      contact: data
    },
    success: function(data) {
      console.log('succes', data);
    }
  });
}

function validateInputValue(e) {
  let valid = true;
  const $inputs = getInputs(e);

  if($inputs.length == 0) return valid;

  // for contact form
  const $contactForm = $(e.target).closest('.carousel-item').find('form.brand_finder_contact');
  if($contactForm.length == 1) {
    return $contactForm.valid();
  }

  $inputs.each(function(i,input) {
    //ref: https://stackoverflow.com/questions/17162334/how-to-use-continue-in-jquery-each-loop
    if($(input).attr('required') != 'required') return; // skip if not required

    valid = !!input.value
    toggleInputError(input, valid)
    if(!valid) return false; // return if it's false
  })
  return valid;
}

function toggleInputError(input, valid) {
  const $input  = $(input);
  const $elem = $input.next();
  if( (/invalid-feedback/i).test($elem.attr('class')) ) {
    $input.toggleClass('is-invalid', !valid);
  }
}

function getInputs(e) {
  return $(e.target).closest('.carousel-item').find('textarea,input');
}

function goPrevious(e) {
  e.preventDefault();
  window.brandSlider.carousel('prev');
}

function scrollToTop($elem) {
  $elem.animate(
    {
      scrollTop: 0,
    },
    680
  )
}

function lockPage(e, bool = true) {
  const reg = /tool=only/i; // it's tool only
  if(reg.test(window.location.href)) { bool = true }

  $('body').toggleClass('lock-tool', bool)
}

function setCookie(e) {
  const $inputs = getInputs(e);
  if($inputs.length == 0) return;

  $inputs.each(function(i,input) {
    const name = input.name;
    let value = input.value;
    if (input.type == 'checkbox') { value = input.checked; }
    Cookies.set(name, value);
  })
}

// https://ishadeed.com/article/flexbox-gap/
function checkFlexGap() {
  // create flex container with row-gap set
  var flex = document.createElement("div");
  flex.style.display = "flex";
  flex.style.flexDirection = "column";
  flex.style.rowGap = "1px";

  // create two, elements inside it
  flex.appendChild(document.createElement("div"));
  flex.appendChild(document.createElement("div"));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  var isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
  flex.parentNode.removeChild(flex);

  return isSupported;
}


export { brandFactFinder };
